import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/(navigation)/api-keys": [6,[2]],
		"/(navigation)/appointments": [7,[2]],
		"/(navigation)/assistants": [8,[2]],
		"/(navigation)/assistants/new": [14,[2]],
		"/(navigation)/assistants/[slug]": [~9,[2,3]],
		"/(navigation)/assistants/[slug]/details": [10,[2,3]],
		"/(navigation)/assistants/[slug]/details/edit": [11,[2,3]],
		"/(navigation)/assistants/[slug]/integrations": [12,[2,3]],
		"/(navigation)/assistants/[slug]/phone-numbers": [13,[2,3]],
		"/auth/callback/auth0": [~26,[4]],
		"/auth/custom/callback/calendly": [~27,[4]],
		"/auth/custom/callback/google": [~28,[4]],
		"/auth/my/signin": [29],
		"/(navigation)/batch-calls/[slug]": [15,[2]],
		"/(navigation)/calls": [16,[2]],
		"/(navigation)/calls/send": [18,[2]],
		"/(navigation)/calls/send/batch": [~19,[2]],
		"/(navigation)/calls/send/batch/select-goal": [~20,[2]],
		"/(navigation)/calls/[slug]": [~17,[2]],
		"/(navigation)/dashboard": [21,[2]],
		"/(navigation)/integrations": [22,[2]],
		"/(navigation)/integrations/calendly": [23,[2]],
		"/(navigation)/integrations/google": [24,[2]],
		"/(navigation)/integrations/zapier": [25,[2]],
		"/onboarding": [30],
		"/p/onboarding": [31]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';